import Colors from "../../styles/colors";
import styled from "styled-components";
import { lighten, darken } from 'polished';

export const Container = styled.div`
  display: flex;
  width: calc(100% - 40px);
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 480px) {
    width: 100%;
    min-height: calc(100vh - 80px);
  }
`;

export const BarChartContainer = styled.div`
  display: flex;
  width: 100%;
  height: 400px;
  border-top: 1px solid ${Colors.deepDark};
  justify-content: center;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid #222222;
  border-radius: 8px;
  margin-top: 20px;
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #222222;
`;

export const SearchInput = styled.input`
  width: 200px;
  height: 40px;
  border: none;
  outline: none;
  padding-left: 10px;
  background-color: #d9d9d9;
  border-radius: 8px;
`;

export const ListContent = styled.div`
  display: flex;
  height: 300px;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
`;

export const HeaderTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  width: 30%;
  display: flex;
  justify-content: flex-end;
`;

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid ${Colors.divider};
  cursor: pointer;
`;

export const Item = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid ${Colors.divider};
  cursor: pointer;
  background-color: ${({ $isSelected }) => ($isSelected ? Colors.divider : 'transparent')};

  &:hover {
    background-color: ${Colors.divider};
  }
`;

export const ItemContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;


export const ItemName = styled.div`
  font-size: 16px;
  font-weight: bold;
  width: 30%;
`;

export const ItemCounts = styled.div`
  display: flex;
  font-size: 14px;
  width: 30%;
  justify-content: flex-end;
`;

const mainColor = Colors.main;
const positiveColor = mainColor;
const slightlyPositiveColor = lighten(0.1, mainColor);
const neutralColor = lighten(0.2, mainColor);
const slightlyNegativeColor = darken(0.1, mainColor);
const negativeColor = darken(0.2, mainColor);

const geminiColors = [
  positiveColor,
  slightlyPositiveColor,
  neutralColor,
  slightlyNegativeColor,
  negativeColor,
];


export const ItemGemini = styled.div<{ $gemini: number }>`
  font-size: 14px;
  color: white;
  font-weight: bold;
  background-color: ${({ $gemini }) => (geminiColors[$gemini])};
  padding: 5px;
  border-radius: 8px;

`;

export const ReviewContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
  background-color: ${Colors.gray};
  border-radius: 8px;
`;

export const modalStyle = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  gap: 3,
};

export const ModalButton = styled.button`
  width: 250px;
  height: 50px;
  background-color: ${Colors.main};
  color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  border: 1px solid ${Colors.divider};
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;

  @media (max-width: 480px) {
    width: 250px;
  }
`;

export const Input = styled.input`
  width: 300px;
  height: 40px;
  border: none;
  outline: none;
  font-size: 14px;

  @media (max-width: 480px) {
    height: 30px;
    width: 130px;
  }
`;

export const InputDescription = styled.div`
  width: 320px;
  font-size: 12px;
  color: ${Colors.deepDark};

  @media (max-width: 480px) {
    width: 270px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 20px;
`;

export const SubmitButton = styled.button`
  width: 300px;
  height: 50px;
  background-color: ${Colors.main};
  color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 250px;
    height: 40px;
  }
`;

export const Button = styled.button<{ $backgroundColor: string }>`
  width: 100px;
  height: 40px;
  background-color: ${props => props.$backgroundColor};
  color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 80px;
    height: 35px;
    font-size: 12px;
  }
`;


export const SmallButton = styled.button`
  width: 100px;
  height: 40px;
  background-color: ${Colors.main};
  color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-size: 12px;

  @media (max-width: 480px) {
    height: 30px;
    width: 60px;
    font-size: 11px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
