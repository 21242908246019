import React from "react";
import * as S from './styled';
import Main1 from '../../assets/images/main1.png';
import Main2 from '../../assets/images/main2.png';
import Main3 from '../../assets/images/main3.png';
import Main4 from '../../assets/images/main4.png';
import Main5 from '../../assets/images/main5.png';
import Main6 from '../../assets/images/main6.jpg';
import Main7 from '../../assets/images/main7.jpg';
import Main8 from '../../assets/images/main8.png';
import Main9 from '../../assets/images/main9.png';
import Main10 from '../../assets/images/main10.png';
import Main11 from '../../assets/images/main11.png';
import Main12 from '../../assets/images/main12.png';
import Main13 from '../../assets/images/main13.png';
import Main14 from '../../assets/images/main14.jpg';
import Main15 from '../../assets/images/main15.jpg';
import Main16 from '../../assets/images/main16.png';

const Information: React.FC = () => {
  return (
    <S.Container>
      <S.Image src={Main1} alt="main1" />
      <S.Image src={Main2} alt="main2" />
      <S.Image src={Main3} alt="main3" />
      <S.Image src={Main4} alt="main4" />
      <S.Image src={Main5} alt="main5" />
      <S.Image src={Main6} alt="main6" />
      <S.Image src={Main7} alt="main7" />
      <S.Image src={Main8} alt="main8" />
      <S.Image src={Main9} alt="main9" />
      <S.Image src={Main10} alt="main10" />
      <S.Image src={Main11} alt="main11" />
      <S.Image src={Main12} alt="main12" />
      <S.Image src={Main13} alt="main13" />
      <S.Image src={Main14} alt="main14" />
      <S.Image src={Main15} alt="main15" />
      <S.Image src={Main16} alt="main16" style={{ width: 350 }} />
      <h2>담당자 바로 연락하기</h2>
      <S.ContactButton href="tel:1644-2018">
        전화하기
      </S.ContactButton>
    </S.Container>
  );
}

export default Information;
