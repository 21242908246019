import styled from "styled-components";
import Colors from "../../styles/colors";

export const Container = styled.div<{ $openMenu: boolean, $openMenuColor: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  height: 70px;
  width: 100%;
  // position: fixed;
  // top: 0;
  // left: 0;
  z-index: 100;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;

  @media (max-width: 992px) {
    min-height: 50px;
    height: 50px;
  }

  @media (max-width: 480px) {
    min-height: ${(props) => (props.$openMenu ? "100vh" : "60px")};
    height: ${(props) => (props.$openMenu ? "100vh" : "60px")};
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${Colors.mobileDarkSection};
  z-index: 100;
`;

export const MobileMenuContainer = styled.div<{ $width: number }>`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: ${(props) => props.$width * 0.7}px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 0 15px 0 15px;
  overflow-y: auto;
  justify-content: space-between;
`;

export const MobileMenuTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 30px;
  margin-top: 20px;
  border-bottom: 1px solid #e0e0e0;

  @media (max-height: 600px) {
    gap: 20px;
    margin-top: 10px;
  }
`;

export const MobileMenuList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
`;

export const MobileMenuItem = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  text-decoration: none;
  cursor: pointer;
`;

export const MobileMenuItemLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: black;
  font-size: 20px;
`;

export const MobileLogoutButton = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  color: ${Colors.charcole};
  font-size: 18px;
  font-family: Inter;
  font-weight: medium;
  cursor: pointer;
  bottom: 20px;
  left: 5%;
  gap: 10px;
  padding-top: 50px;
  padding-bottom: 15px;
`;

export const MobileMenuMessage = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 18px;
  font-family: Inter;
  font-weight: medium;

  @media (max-height: 600px) {
    font-size: 16px;
  }

  @media (max-width: 320px) {
    font-size: 14px;
  }
`;

export const Logo = styled.img`
  height: 50px;
  width: auto;
  padding-left: 20px;
  cursor: pointer;

  @media (max-width: 992px) {
    height: 30px;
    width: auto;
    padding-left: 15px;
  }

  @media (max-width: 480px) and (min-height: 601px) {
    width: 240px;
    height: auto;
  }

  @media (max-width: 480px) and (max-height: 600px) {
    width: 80%;
    height: auto;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-right: 20px;

  @media (max-width: 992px) {
    gap: 15px;
    padding-right: 15px;
  }
`;

export const TextButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  gap: 5px;
  font-size: 14px;
  font-family: Inter;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 992px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`

export const Button = styled.a<{ $backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: ${(props) => props.$backgroundColor};
  color: white;
  border-radius: 10px;
  padding: 0 5px;
  font-size: 14px;
  font-family: Inter;
  font-weight: medium;
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 992px) {
    width: 80px;
    height: 30px;
    font-size: 12px;
  }

  @media (max-width: 480px) {
    width: 70%;
    height: 40px;
    font-size: 16px;
  }
`;
