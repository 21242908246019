import { useCallback, useEffect, useState } from "react";
import * as S from "./styled";
import axios from "axios";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";

const API_URL = process.env.REACT_APP_API_URL;

const remainingTimeFormat = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
}

const validatePassword = (password: string): boolean => {
  // 비밀번호는 문자, 숫자, 특수문자(~!@#$%^&*)의 조합 10 ~ 16자리로 입력이 가능합니다.
  const passwordRegExp = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[~!@#$%^&*])[a-zA-Z0-9~!@#$%^&*]{10,16}$/;
  return passwordRegExp.test(password);
}

const validatePhone = (phone: string): boolean => {
  // 휴대폰 번호는 '-'를 제외하고 입력해주세요. + 010으로 시작하는 11자리 숫자
  const phoneRegExp = /^010\d{8}$/;
  return phoneRegExp.test(phone);
}

const SignIn = () => {
  const [remainingTime, setRemainingTime] = useState<number>(300);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [id, setId] = useState<string>("");
  const [isIdDuplicated, setIsIdDuplicated] = useState<boolean | null>(null);
  const [password, setPassword] = useState<string>("");
  const [isPasswordValid, setIsPasswordValid] = useState<boolean | null>(null);
  const [rePassword, setRePassword] = useState<string>("");
  const [isRePasswordValid, setIsRePasswordValid] = useState<boolean | null>(null);
  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [phoneMessage, setPhoneMessage] = useState<string>("");
  const [authNumber, setAuthNumber] = useState<string>("");
  const [responseAuthNumber, setResponseAuthNumber] = useState<string>("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleDuplicateCheck = useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/users/duplicate`, { "user_id": id });
      setIsIdDuplicated(response.data.is_duplicate);
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  const handleSendAuthNumber = useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!validatePhone(phone)) {
      setPhoneMessage("휴대폰 번호를 확인해주세요.");
      return;
    }
    try {
      const response = await axios.post(`${API_URL}/auth/auth-phone`, { "phone_number": phone });
      setRemainingTime(300);
      if (timer) {
        clearInterval(timer);
      }
      const newTimer = setInterval(() => {
        setRemainingTime((prev) => prev - 1);
      }, 1000);
      setTimer(newTimer);
      setResponseAuthNumber(response.data.auth_number);
    } catch (error) {
      console.error(error);
    }
  }, [phone, timer]);

  const handleSignUp = useCallback(async (event: React.FormEvent) => {
    event.preventDefault();
    if (id === "" || password === "" || rePassword === "" || name === "" || phone === "" || authNumber === "") {
      alert("모든 항목을 입력해주세요.");
      return;
    }
    try {
      const response = await axios.post(`${API_URL}/users/duplicate`, { "user_id": id });
      if (response.data.is_duplicate) {
        setIsIdDuplicated(true);
        alert("아이디가 중복되었습니다.");
      } else {
        setIsIdDuplicated(false);
      }
    } catch (error) {
      console.error(error);
    }
    if (isPasswordValid === false) {
      alert("비밀번호를 확인해주세요.");
      return;
    }
    if (isRePasswordValid === false) {
      alert("비밀번호 재입력을 확인해주세요.");
      return;
    }
    if (!validatePhone(phone)) {
      alert("휴대폰 번호를 확인해주세요.");
      return;
    }
    if (authNumber !== responseAuthNumber.toString()) {
      alert("인증 번호를 확인해주세요.");
      return;
    }
    if (remainingTime <= 0) {
      alert("인증 번호가 만료되었습니다.");
      return;
    }
    try {
      const response = await axios.post(`${API_URL}/auth/sign-in`, {
        "id": id,
        "password": password,
        "name": name,
        "phone_number": phone
      });
      if (response.status === 200) {
        alert("회원가입이 완료되었습니다.");
        await login(id, password);
        navigate("/");
      } else {
        alert("회원가입에 실패했습니다. 다시 시도해주세요.");
      }
    } catch (error) {
      console.error(error);
    }
  }, [id, password, rePassword, name, phone, authNumber, responseAuthNumber, isPasswordValid, isRePasswordValid, remainingTime, login, navigate]);

  useEffect(() => {
    if (password === "") {
      setIsPasswordValid(null);
    } else {
      setIsPasswordValid(validatePassword(password));
    }
  }, [password]);

  useEffect(() => {
    if (rePassword === "") {
      setIsRePasswordValid(null);
    } else {
      setIsRePasswordValid(password === rePassword);
    }
  }, [rePassword, password]);

  useEffect(() => {
    if (remainingTime <= 0 && timer) {
      clearInterval(timer);
    }
  }, [remainingTime, timer]);

  return (
    <S.Container>
      <S.Title>회원가입</S.Title>
      <S.Form>
        <S.InputContainer>
          <S.InputWrapper>
            <S.Input type="text" placeholder="아이디 입력" value={id} onChange={(e) => setId(e.target.value)} />
            <S.ButtonContainer>
              {isIdDuplicated === false ? <CheckIcon style={{ "color": "green" }} /> : isIdDuplicated === true ? <CloseIcon style={{ "color": "red" }} /> : null}
              <S.SmallButton onClick={(e) => handleDuplicateCheck(e)}>중복 확인</S.SmallButton>
            </S.ButtonContainer>
          </S.InputWrapper>
          <S.InputDescription>아이디는 6-20자 사이로 입력해주세요.</S.InputDescription>
        </S.InputContainer>
        <S.InputContainer>
          <S.InputWrapper>
            <S.Input type="password" placeholder="비밀번호 입력" value={password} onChange={(e) => setPassword(e.target.value)} />
            {isPasswordValid === false ? <CloseIcon style={{ "color": "red" }} /> : isPasswordValid === true ? <CheckIcon style={{ "color": "green" }} /> : null}
          </S.InputWrapper>
          <S.InputDescription>비밀번호는 문자, 숫자, 특수문자(~!@#$%^&*)의 조합
            10 ~ 16자리로 입력이 가능합니다.</S.InputDescription>
        </S.InputContainer>
        <S.InputContainer>
          <S.InputWrapper>
            <S.Input type="password" placeholder="비밀번호 재입력" value={rePassword} onChange={(e) => setRePassword(e.target.value)} />
            {isRePasswordValid === false ? <CloseIcon style={{ "color": "red" }} /> : isRePasswordValid === true ? <CheckIcon style={{ "color": "green" }} /> : null}
          </S.InputWrapper>
        </S.InputContainer>
        <S.InputWrapper>
          <S.Input type="text" placeholder="이름" value={name} onChange={(e) => setName(e.target.value)} />
        </S.InputWrapper>
        <S.InputContainer>
          <S.InputWrapper>
            <S.Input type="text" placeholder="휴대폰 번호 입력" value={phone} onChange={(e) => setPhone(e.target.value)} />
            <S.SmallButton onClick={(e) => handleSendAuthNumber(e)}>문자 인증</S.SmallButton>
          </S.InputWrapper>
          <S.InputDescription>휴대폰 번호는 '-'를 제외하고 입력해주세요.</S.InputDescription>
          <S.InputDescription style={{ "color": "red" }}>{phoneMessage}</S.InputDescription>
        </S.InputContainer>
        <S.InputWrapper>
          <S.Input type="text" placeholder="인증 번호" value={authNumber} onChange={(e) => setAuthNumber(e.target.value)} />
          <S.Timer>{remainingTimeFormat(remainingTime)}</S.Timer>
        </S.InputWrapper>
        <S.SubmitButton onClick={handleSignUp}>가입하기</S.SubmitButton>
      </S.Form>
    </S.Container>
  );
};

export default SignIn;
