import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import SliderImage1 from "../../../assets/images/home1.png";

const ImageSlider: React.FC = () => {
  const images: string[] = [
    SliderImage1
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Box sx={{ position: 'relative', textAlign: 'center', width: '100%', maxWidth: '700px', margin: 'auto' }}>
      <Box
        sx={{
          width: '100%',
          paddingTop: '100%', // 1:1 비율을 맞추기 위한 설정
          position: 'relative',
        }}
      >
        <img
          src={images[currentImageIndex]}
          alt="store"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover', // 이미지를 비율에 맞게 조정
          }}
        />
      </Box>
      <Box sx={{ position: 'absolute', top: '50%', left: '0', transform: 'translateY(-50%)' }}>
        <IconButton onClick={handlePrevClick}>
          <ArrowBack />
        </IconButton>
      </Box>
      <Box sx={{ position: 'absolute', top: '50%', right: '0', transform: 'translateY(-50%)' }}>
        <IconButton onClick={handleNextClick}>
          <ArrowForward />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ImageSlider;
