import { useSearchParams } from "react-router-dom"
import * as S from "./styled"

export function FailPage() {
  const [searchParams] = useSearchParams()

  // 고객에게 실패 사유 알려주고 다른 페이지로 이동

  return (
    <S.Container>
      <h1>결제 실패</h1>
      <div>{`사유: ${searchParams.get("message")}`}</div>
    </S.Container>
  )
}
