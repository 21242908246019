import styled from "styled-components";
import Colors from "../../styles/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 70px);
  gap: 30px;
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 480px) {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: calc(70vh - 60px);
  }
`;

export const ApplyButton = styled.button`
  width: 70px;
  height: 40px;
  background-color: #363636;
  color: white;
  border: none;
  `;

export const SubmitButton = styled.button`
  width: 300px;
  height: 50px;
  background-color: ${Colors.main};
  color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 250px;
    height: 40px;
  }
`;

export const BillContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Bill = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
`;

export const BillTitle = styled.span`
  font-weight: bold;
`;
