import { useEffect } from "react";
import * as S from './styled';
import { useNavigate } from "react-router-dom";
import Home1 from '../../assets/images/home1.png';
import Colors from "../../styles/colors";
import { useAuth } from "../../hooks/useAuth";

interface MainProps {
  width: number;
}

const Main = ({ width }: MainProps) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleStart = () => {
    if (auth.state.userPlaces && auth.state.userPlaces.length > 0) {
      navigate("/dashboard");
    } else if (auth.state.isAuthenticated) {
      navigate("/apply");
    } else {
      navigate("/login");
    }
  }

  return (
    <S.Container>
      {width <= 480 && <S.Image src={Home1} alt="home1" />}
      <S.Description>
        <S.DescriptionContainer>
          <S.DescriptionTitle>AI를 통해</S.DescriptionTitle>
          <S.DescriptionTitle>리뷰를 분석해드립니다!</S.DescriptionTitle>
        </S.DescriptionContainer>
        <S.DescriptionContainer>
          <S.DescriptionText>
            리뷰알리미는 플레이스의 리뷰를
          </S.DescriptionText>
          <S.DescriptionText>
            실시간으로 분석하는
          </S.DescriptionText>
          <S.DescriptionText>
            AI 리뷰 마케팅 솔루션 입니다.
          </S.DescriptionText>
        </S.DescriptionContainer>
        <S.ButtonContainer>
          <S.Button $backgroundColor={Colors.main} onClick={handleStart}>
            {auth.state.userPlaces && auth.state.userPlaces.length > 0 ? "관리하기" : auth.state.isAuthenticated ? "등록하기" : "시작하기"}
          </S.Button>
          <S.Button $backgroundColor={Colors.deepDark} onClick={() => navigate("/information")}>
            더 알아보기
          </S.Button>
        </S.ButtonContainer>
      </S.Description>
      {width > 480 && <S.Image src={Home1} alt="home1" />}
    </S.Container>
  );
}

export default Main;
