import React, { useState } from "react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import Colors from "../../../styles/colors";
import { useNavigate } from "react-router-dom";

const PurchaseDetails: React.FC = () => {
  const [size, setSize] = useState<string>("");
  const [quantity, setQuantity] = useState<number>(1);
  const navigate = useNavigate();

  const handleSizeChange = (event: SelectChangeEvent<string>) => {
    setSize(event.target.value as string);
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value);
    if (isNaN(value)) value = 1;
    if (value < 1) value = 1;
    if (value > 10) value = 10;
    setQuantity(value);
  };

  const calculatePrice = () => {
    const unitPrice = 29000; // 단위 가격 예시
    return unitPrice * quantity;
  };

  const handlePurchase = () => {
    const queryString = `?type=${size}&quantity=${quantity}`;
    navigate(`/payment${queryString}`);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Typography variant="h5" gutterBottom>
        리뷰알리미 서비스
      </Typography>
      <Typography paragraph>
        리뷰알리미 서비스는 사용자가 등록한 상품의 리뷰가 등록되었을 때 알림을 받을 수 있는 서비스입니다.
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="size-select-label">상품 분류</InputLabel>
        <Select
          labelId="size-select-label"
          id="size-select"
          value={size}
          label="category"
          onChange={handleSizeChange}
        >
          <MenuItem value="1month">1개월 체험권</MenuItem>
          <MenuItem value="subscribe">정기결제</MenuItem>
        </Select>
      </FormControl>

      {size && (
        <>
          <TextField
            id="quantity"
            label="수량"
            type="number"
            InputProps={{ inputProps: { min: 1, max: 10 } }}
            value={quantity}
            onChange={handleQuantityChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          {
            size === "1month" && (
              <Typography paragraph>
                1개월 체험권은 1개월 동안 특정 플레이스의 리뷰 관리 서비스를 체험 하실 수 있습니다.
              </Typography>
            )
          }
          {
            size === "subscribe" && (
              <Typography paragraph>
                정기결제는 정기결제 기간 동안 특정 플레이스의 리뷰 관리 서비스를 이용하실 수 있습니다.
              </Typography>
            )
          }
          <Typography variant="h6" gutterBottom>
            총 가격: {calculatePrice().toLocaleString()}원
          </Typography>
          <Button
            variant="contained"
            sx={{ backgroundColor: Colors.main, '&:hover': { backgroundColor: Colors.main } }}
            fullWidth
            onClick={handlePurchase}
          >
            구매하기
          </Button>
        </>
      )}
    </Box>
  );
};

export default PurchaseDetails;
