import * as S from "./styled";

const MyPlace = () => {
  return (
    <S.Container>
      MyPlace
    </S.Container>
  );
}

export default MyPlace
