import * as S from './styled';
import * as C from "./container";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState } from 'react';
import Colors from '../../../styles/colors';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Recover = () => {
  const [value, setValue] = useState(0);
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [passwordUsername, setPasswordUsername] = useState<string>('');
  const [authNumber, setAuthNumber] = useState<string>('');
  const [isFindId, setIsFindId] = useState<boolean | null>(null);
  const [findId, setFindId] = useState<string>('');
  const [findPasswordIndex, setFindPasswordIndex] = useState<number>(0);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <S.Container>
      <S.Title>아이디 / 비밀번호 찾기</S.Title>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="basic tabs example" sx={{
            width: '100%',
            '& .MuiTabs-indicator': {
              backgroundColor: Colors.main,
            },
            '& .MuiTab-root': {
              color: Colors.charcole,
              '&.Mui-selected': {
                color: Colors.main,
              },
            },
          }}>
            <Tab label="아이디 찾기" {...a11yProps(0)} sx={{ width: '50%', fontWeight: "bold" }} />
            <Tab label="비밀번호 찾기" {...a11yProps(1)} sx={{ width: '50%', fontWeight: "bold" }} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <S.Form>
            {isFindId === null ?
              <C.FindId name={name} setName={setName} phone={phone} setPhone={setPhone} setIsFindId={setIsFindId} setFindId={setFindId} />
              : isFindId ?
                <>
                  <div>
                    회원님의 아이디는 {findId} 입니다.
                  </div>
                  <S.SubmitButton onClick={() => navigate("/login")}>로그인</S.SubmitButton>
                </>
                :
                <>
                  <div>아이디를 찾을 수 없습니다.</div>
                  <S.SubmitButton onClick={() => setIsFindId(null)}>다시 시도</S.SubmitButton>
                </>
            }
          </S.Form>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <S.Form>
            {findPasswordIndex === 0 ?
              <C.RecoverPasswordBeforeAuth userId={userId} setUserId={setUserId} passwordUsername={passwordUsername} setPasswordUsername={setPasswordUsername} setFindPasswordIndex={setFindPasswordIndex} setAuthNumber={setAuthNumber} />
              : findPasswordIndex === 1 ?
                <C.RecoverPasswordAfterAuth authNumber={authNumber} setFindPasswordIndex={setFindPasswordIndex} />
                :
                <C.RecoverPassword userId={userId} name={passwordUsername} setFindPasswordIndex={setFindPasswordIndex} />
            }
          </S.Form>
        </CustomTabPanel>
      </Box>
    </S.Container>
  );
}

export default Recover;
