import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: calc(100% - 40px);
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  gap: 20px;

  @media (max-width: 480px) {
    width: 100%;
    min-height: calc(100vh - 80px);
  }
`;
