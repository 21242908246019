import { useEffect } from "react";
import * as S from "./styled";

const CardApplySuccess = () => {
  // 쿼리스트링 파라미터로 카드 발급 성공 여부를 받아와서 렌더링
  const urlParams = new URLSearchParams(window.location.search);
  const customerKey = urlParams.get("customerKey");
  const authKey = urlParams.get("authKey");

  useEffect(() => {
    console.log(`customerKey: ${customerKey}, authKey: ${authKey}`);
    //http://localhost:3000/card-apply-success?customerKey=cBQ_M4QoHKqik8NWmtmjq&authKey=bln_WW4EmdjEXbQ
  }, []);

  return (
    <S.Container>
      <h1>카드 신청이 완료되었습니다.</h1>
      <p>카드 발급까지 7일이 소요됩니다.</p>
    </S.Container>
  );
};

export default CardApplySuccess;
