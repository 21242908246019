import * as S from "./styled"
import * as C from "./container"
import { useEffect, useState } from "react";
import Colors from "../../styles/colors";
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

interface HeaderProps {
  width: number;
  setCurrentPath: React.Dispatch<React.SetStateAction<string>>;
}

const Header = ({ width, setCurrentPath }: HeaderProps) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const location = useLocation();
  const auth = useAuth();

  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/signin") setCurrentPath("/");
    else setCurrentPath(location.pathname);
  }, [location.pathname, setCurrentPath]);

  useEffect(() => {
    const syncUser = async () => {
      await auth.syncUser();
    }
    syncUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.Container $openMenu={openMenu} $openMenuColor={Colors.mobileDarkSection}>
      {width > 480 ? (
        <C.DesktopHeader />
      ) : openMenu ? (
        <C.MobileOpenMenuHeader width={width} setOpenMenu={setOpenMenu} />
      ) : (
        <MenuIcon style={{ fontSize: "30px", paddingLeft: "15px", color: Colors.charcole }} onClick={() => setOpenMenu(true)} />
      )}
    </S.Container>
  );
}

export default Header;
