import * as S from "./styled";
import * as C from "./container";
import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';
import Colors from "../../styles/colors";
import { useAuth } from "../../hooks/useAuth";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: "100%", height: `calc(100% - 92px)`, display: 'flex' }}
    >
      {value === index && (
        <Box sx={{ width: '100%', height: '100%', display: "flex" }}>
          <Typography component="div" sx={{ height: '100%', display: "flex", width: "100%" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface DashboardProps {
  width: number;
}

const Dashboard = ({ width }: DashboardProps) => {
  const [value, setValue] = useState(0);
  const isMobile = useMediaQuery('(max-width:768px)');
  const auth = useAuth();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <S.Container>
      <Box sx={{ display: "flex", width: '100%', height: '100%', flexDirection: "column", alignItems: "center" }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", height: 48, width: "100%" }}>
          <Tabs value={value} onChange={handleChange} variant="standard" aria-label="basic tabs example" sx={{
            width: '100%',
            '& .MuiTabs-indicator': {
              backgroundColor: Colors.main,
            },
            '& .MuiTab-root': {
              color: Colors.charcole,
              '&.Mui-selected': {
                color: Colors.main,
              },
            },
          }}>
            <Tab label="매장 목록" {...a11yProps(0)} sx={{ fontWeight: "bold" }} />
            <Tab label="알림메세지" {...a11yProps(1)} sx={{ fontWeight: "bold" }} />
            {auth.state && auth.state.admin > 0 && <Tab label="영업" {...a11yProps(2)} sx={{ fontWeight: "bold" }} />}
            {auth.state && auth.state.admin === 2 && <Tab label="관리" {...a11yProps(3)} sx={{ fontWeight: "bold" }} />}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <C.StoreList width={width} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <C.Messages />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <C.Business />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <C.Admin />
        </CustomTabPanel>
      </Box>
    </S.Container>
  );
}

export default Dashboard;
