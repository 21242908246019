const Colors = {
  black: '#000000',
  white: '#FFFFFF',
  gray: '#F5F5F5',
  lightGray: '#E5E5E5',
  darkGray: '#363636',
  red: '#FF0000',
  blue: '#0000FF',
  green: '#00FF00',
  yellow: '#FFFF00',
  orange: '#FFA500',
  purple: '#800080',
  pink: '#FFC0CB',
  brown: '#A52A2A',
  cyan: '#00FFFF',
  lime: '#00FF00',
  magenta: '#FF00FF',
  silver: '#C0C0C0',
  teal: '#008080',
  maroon: '#800000',
  olive: '#808000',
  navy: '#000080',
  indigo: '#4B0082',
  aqua: '#00FFFF',
  bisque: '#FFE4C4',
  burlywood: '#DEB887',
  cadetblue: '#5F9EA0',
  chartreuse: '#7FFF00',
  chocolate: '#D2691E',
  coral: '#FF7F50',
  cornflowerblue: '#6495ED',
  cornsilk: '#FFF8DC',
  crimson: '#DC143C',
  darkblue: '#00008B',
  darkcyan: '#008B8B',
  darkgoldenrod: '#B8860B',
  darkgreen: '#006400',
  darkkhaki: '#BDB76B',
  darkmagenta: '#8B008B',
  darkolivegreen: '#556B2F',
  darkorange: '#FF8C00',
  darkorchid: '#9932CC',
  darkred: '#8B0000',
  darksalmon: '#E9967A',
  darkseagreen: '#8FBC8F',
  darkslateblue: '#483D8B',
  darkslategray: '#2F4F4F',
  darkturquoise: '#00CED1',
  darkviolet: '#9400D3',
  deeppink: '#FF1493',
  deepskyblue: '#00BFFF',
  dimgray: '#696969',
  dodgerblue: '#1E90FF',
  firebrick: '#B22222',
  charcole: "#434343",
  main: "#4BAE52",
  deepDark: "#222222",
  mobileDarkSection: "rgba(0, 0, 0, 0.8)",
  divider: "#E0E0E0",
};

export default Colors;
