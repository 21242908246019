import styled from "styled-components";
import Colors from "../../../styles/colors";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  font-family: inter;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`;

export const Input = styled.input`
  width: 300px;
  height: 30px;
  border: 1px solid black;
  padding: 10px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid ${Colors.divider};

  @media (max-width: 480px) {
    width: 250px;
    height: 20px;
  }
`;

export const SubmitButton = styled.button`
  width: 150px;
  height: 40px;
  background-color: ${Colors.main};
  color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;
`;

export const OtherOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 320px;

  @media (max-width: 480px) {
    width: 270px;
  }
`;

export const OtherOption = styled.a`
  color: ${Colors.black};
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  font-family: inter;
  font-weight: bold;
`;

export const Logo = styled.img`
  height: 50px;
  width: auto;
`;
