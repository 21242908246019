import * as S from './styled';

interface FooterProps {
  width: number;
}

const Footer = ({ width }: FooterProps) => {
  return (
    <S.FooterContainer $width={width}>
      <S.TextContainer>
        <div>(주)상상력집단 | 대표: 송진혁</div>
        <div>사업자 등록번호 170-87-02633</div>
        <div>서울특별시 서초구 언남11길 16-15, 4층(양재동)</div>
        <div>1644-2018 | ss2443515@illusionists.co.kr</div>
      </S.TextContainer>
      <S.TermsAndPrivacyContainer>
        <S.TermsAndPrivacyLink href='/terms'>이용약관</S.TermsAndPrivacyLink>
        <S.TermsAndPrivacyLink href='/privacy'>개인정보처리방침</S.TermsAndPrivacyLink>
      </S.TermsAndPrivacyContainer>
    </S.FooterContainer>
  );
}

export default Footer;
