import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 50px;
  min-height: 100vh;

  @media (max-width: 768px) {
    gap: 15px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 30px;
    min-height: 800px;
    overflow-y: auto;
  }
`;

export const Description = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  gap: 30px;
  font-family: inter;

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 480px) {
    width: 100%;
    align-items: center;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    align-items: center;
  }
`;

export const DescriptionTitle = styled.div`
  font-size: 40px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const DescriptionText = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: #888888;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const Image = styled.img`
  display: flex;
  width: 40%;
  height: auto;
  object-fit: cover;

  @media (max-width: 480px) {
    width: 80%;
  }
`;

export const Button = styled.button<{ $backgroundColor: string }>`
  width: 100px;
  height: 40px;
  background-color: ${props => props.$backgroundColor};
  color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 80px;
    height: 35px;
    font-size: 12px;
  }
`;
