import { useEffect, useState } from 'react';
import * as S from './styled';
import axios from 'axios';
import Loading from "../../../assets/images/loading.gif";
import Colors from '../../../styles/colors';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';

const API_URL = process.env.REACT_APP_API_URL;

interface ApplyProps {
  url: string;
  phone: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
}

const validatePhone = (phone: string): boolean => {
  // 휴대폰 번호는 '-'를 제외하고 입력해주세요. + 010으로 시작하는 11자리 숫자
  const phoneRegExp = /^010\d{8}$/;
  return phoneRegExp.test(phone);
}

const Apply = ({ url, setUrl, phone, setPhone }: ApplyProps) => {
  const [index, setIndex] = useState<number>(0);
  const [storeName, setStoreName] = useState<string>('');
  const { applyPlace, state } = useAuth();
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const sales = query.get('sales');

  const handlePayment = () => {
    navigate(sales ? `/payment?sales=${sales}` : '/payment')
  }

  const handleValidateUrl = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (url === '') {
      alert('네이버 플레이스 URL을 입력해주세요.');
      return;
    }
    setIndex(1);
    try {
      const response = await axios.post(`${API_URL}/place/validate-url`, { url });
      setStoreName(response.data);
      if (state.userPlaces && state.userPlaces.some(place => place.url === url && place.place_name === response.data)) {
        alert('이미 등록된 URL입니다.');
        setIndex(0); // 중복된 경우 인덱스를 초기화
        return;
      }
      setIndex(2);
    } catch (error) {
      alert('올바르지 않은 URL입니다. 다시 확인해주세요.');
      setIndex(0);
    }
  }

  const handleSendSms = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (phone === '') {
      alert('휴대폰 번호를 입력해주세요.');
      return;
    }
    if (!validatePhone(phone)) {
      alert('휴대폰 번호를 확인해주세요.');
      return;
    }
    try {
      await axios.post(`${API_URL}/place/send-sms-test`, { "phone_number": phone });
      applyPlace(url, storeName, phone);
      setUrl('');
      setPhone('');
      setIndex(4);
    } catch (error) {
      alert('문자 전송에 실패했습니다. 다시 시도해주세요.');
      setIndex(3);
    }
  }

  return (
    <S.Container>
      <S.Title>플레이스 등록</S.Title>
      {index === 0 ?
        <S.Form>
          <S.InputContainer>
            <S.InputWrapper>
              <S.Input type="text" placeholder="네이버 플레이스 URL" value={url} onChange={(e) => setUrl(e.target.value)} />
            </S.InputWrapper>
            <S.InputDescription>{`네이버 지도 -> 플레이스 검색 -> "공유" 버튼 클릭`}</S.InputDescription>
          </S.InputContainer>
          <S.SubmitButton onClick={(e) => handleValidateUrl(e)}>등록하기</S.SubmitButton>
        </S.Form>
        : index === 1 ?
          <S.Form>
            <div>URL을 검사 중입니다.</div>
            <img src={Loading} alt="loading" />
          </S.Form>
          : index === 2 ?
            <S.Form>
              <div>URL이 올바르게 검사되었습니다.</div>
              <div>{`가게 이름: ${storeName}`}</div>
              <S.ButtonContainer>
                <S.Button onClick={() => setIndex(0)} $backgroundColor={Colors.deepDark} >다시 입력</S.Button>
                <S.Button $backgroundColor={Colors.main} onClick={() => setIndex(3)}>다음</S.Button>
              </S.ButtonContainer>
            </S.Form>
            : index === 3 ?
              <S.Form>
                <div>문자 받으실 휴대폰 번호를 입력해주세요.</div>
                <S.InputContainer>
                  <S.InputWrapper>
                    <S.Input type="text" placeholder="휴대폰 번호 입력" value={phone} onChange={(e) => setPhone(e.target.value)} />
                  </S.InputWrapper>
                  <S.InputDescription>휴대폰 번호는 '-'를 제외하고 입력해주세요.</S.InputDescription>
                </S.InputContainer>
                <S.SubmitButton onClick={(e) => handleSendSms(e)}>문자 전송</S.SubmitButton>
              </S.Form>
              :
              <S.Form>
                <div>문자가 전송되었습니다. 잠시만 기다려주세요.</div>
                <div>문자를 받으셨다면, 확인 버튼을 눌러주세요.</div>
                <S.ButtonContainer>
                  <S.Button onClick={() => setIndex(3)} $backgroundColor={Colors.deepDark}>다시 입력</S.Button>
                  <S.Button onClick={handlePayment} $backgroundColor={Colors.main}>확인</S.Button>
                  {/* <S.Button onClick={() => navigate('/success')} $backgroundColor={Colors.main}>확인</S.Button> */}
                </S.ButtonContainer>
              </S.Form>
      }
    </S.Container>
  )
};

export default Apply;
