import axios from "axios";
import { ReactNode, useContext, useReducer, createContext } from "react";

const API_URL = process.env.REACT_APP_API_URL;

type User = {
  id: string;
  grade: number;
  name: string;
  phone_number: string;
  admin: number;
};

type UsersState = {
  users: User[] | null;
};

type UsersAction =
  | { type: 'SET_USERS'; payload: User[] }
  | { type: 'CLEAR_USERS' };

const initialUsersState: UsersState = {
  users: null,
};

const usersReducer = (state: UsersState, action: UsersAction): UsersState => {
  switch (action.type) {
    case 'SET_USERS':
      return {
        ...state,
        users: action.payload,
      };
    case 'CLEAR_USERS':
      return {
        ...state,
        users: null,
      };
    default:
      return state;
  }
};

const UsersContext = createContext<{
  state: UsersState;
  dispatch: React.Dispatch<UsersAction>;
  getUsers: (admin: number) => Promise<void>;
}>({
  state: initialUsersState,
  dispatch: () => null,
  getUsers: async () => { },
});

export const UsersProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(usersReducer, initialUsersState);

  const getUsers = async (admin: number) => {
    try {
      if (admin !== 2) return;
      const response = await axios.get(`${API_URL}/users/users?admin=${admin}`);
      dispatch({ type: 'SET_USERS', payload: response.data });
    } catch (error) {
      console.error("사용자 정보 불러오기 실패:", error);
    }
  }

  return (
    <UsersContext.Provider value={{ state, dispatch, getUsers }}>
      {children}
    </UsersContext.Provider>
  );
};

// 커스텀 훅 생성
export const useUsers = () => useContext(UsersContext);
