import styled from "styled-components";
import Colors from "../../styles/colors";

export const FooterContainer = styled.footer<{ $width: number }>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: ${(props) => props.$width}px;
  min-height: 200px;
  height: 200px;
  flex-direction: row;
  background-color: ${Colors.deepDark};
  font-size: 14px;
  color: white;
  font-family: inter;
  font-weight: regular;

  @media (max-width: 992px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    min-height: 150px;
    height: 150px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    align-items: center;
  }
`;

export const TermsAndPrivacyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-top: 20px;
`;

export const TermsAndPrivacyLink = styled.a`
  color: white;
  text-decoration: none;
`;
