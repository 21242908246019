import styled from "styled-components";
import Colors from "../../../styles/colors";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  width: 800px;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  font-family: inter;

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  border: 1px solid ${Colors.divider};
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;

  @media (max-width: 480px) {
    width: 250px;
  }
`;

export const Input = styled.input`
  width: 160px;
  height: 40px;
  border: none;
  outline: none;
  font-size: 14px;

  @media (max-width: 480px) {
    height: 30px;
    width: 130px;
  }
`;

export const InputDescription = styled.div`
  width: 320px;
  font-size: 12px;
  color: ${Colors.deepDark};

  @media (max-width: 480px) {
    width: 270px;
  }
`;

export const SmallButton = styled.button`
  width: 100px;
  height: 40px;
  background-color: ${Colors.main};
  color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-size: 12px;

  @media (max-width: 480px) {
    height: 30px;
    width: 60px;
    font-size: 11px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const SubmitButton = styled.button`
  width: 300px;
  height: 50px;
  background-color: ${Colors.main};
  color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 250px;
    height: 40px;
  }
`;

export const Timer = styled.div`
  font-size: 14px;
  color: ${Colors.deepDark};
  font-weight: bold;
  font-family: inter;
`;
