import { useEffect, useState } from "react"
import { loadTossPayments, TossPaymentsPayment } from "@tosspayments/tosspayments-sdk";
import * as S from './styled'
// import { nanoid } from "nanoid"
import { useAuth } from "../../hooks/useAuth"
import ProductImage from "../../assets/images/home1.png";
import { useNavigate } from "react-router-dom";

const clientKey = "test_ck_PBal2vxj81y4ZDkR0Pk285RQgOAN";

interface PaymentProps {
  url: string;
  phone: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
}

const Payment = ({ url, phone, setUrl, setPhone }: PaymentProps) => {
  // const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null)
  const price = 29900;
  const query = new URLSearchParams(window.location.search);
  const sales = query.get('sales');
  const type = query.get('type');
  const quantity = query.get('quantity');
  const [payment, setPayment] = useState<TossPaymentsPayment | null>(null);
  const { loadIdAndName, state } = useAuth();
  const amount = {
    currency: "KRW",
    value: quantity ? price * parseInt(quantity) : price,
  };
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchPayment() {
      try {
        const { id, userId, name } = loadIdAndName();
        if (!id || !userId || !name) {
          alert("로그인이 필요합니다.");
          navigate("/login");
          return;
        }
        const tossPayments = await loadTossPayments(clientKey);

        // 회원 결제
        // @docs https://docs.tosspayments.com/sdk/v2/js#tosspaymentspayment
        const payment = tossPayments.payment({
          customerKey: id,
        });

        setPayment(payment);
      } catch (error) {
        console.error("Error fetching payment:", error);
      }
    }

    fetchPayment();
  }, [clientKey, loadIdAndName]);

  const subscribe = async () => {
    if (!payment) return;
    const { id, userId, name } = loadIdAndName();
    if (type === "subscribe") {
      await payment.requestBillingAuth({
        method: "CARD", // 자동결제(빌링)은 카드만 지원합니다
        successUrl: sales ? `${window.location.origin}/success?sales=${sales}&type=${type}&quantity=${quantity}` : `${window.location.origin}/success?type=${type}&quantity=${quantity}`, // 요청이 성공하면 리다이렉트되는 URL
        failUrl: window.location.origin + "/fail", // 요청이 실패하면 리다이렉트되는 URL
        customerEmail: "customer123@gmail.com",
        customerName: name || "김토스",
      });
    } else {
      console.log('id:', id);
      await payment.requestPayment({
        method: "CARD", // 카드 및 간편결제
        amount,
        orderId: id || "orderid", // 고유 주문번호
        orderName: "리뷰 알리미 서비스 1개월", // 주문명
        successUrl: sales ? `${window.location.origin}/success?sales=${sales}&type=${type}&quantity=${quantity}` : `${window.location.origin}/success?type=${type}&quantity=${quantity}`, // 결제 요청이 성공하면 리다이렉트되는 URL
        failUrl: window.location.origin + "/fail", // 결제 요청이 실패하면 리다이렉트되는 URL
        customerEmail: "customer123@gmail.com",
        customerName: name || "김토스",
        customerMobilePhone: state && state.userPhone ? state.userPhone : "01012345678",
        card: {
          useEscrow: false,
          flowMode: "DEFAULT",
          useCardPoint: false,
          useAppCardOnly: false,
        },
      });
    }
  }

  return (
    <S.Container style={{ "gap": "30px" }}>
      <h1>주문서</h1>
      <img
        src={ProductImage}
        alt="store"
        style={{
          width: '300px',
          height: '300px',
          objectFit: 'cover', // 이미지를 비율에 맞게 조정
        }}
      />
      <S.BillContainer>
        <S.Bill>
          <S.BillTitle>상품명</S.BillTitle>
          <span>리뷰 알리미 서비스 {type === "subscribe" ? "정기결제" : "1개월 이용권"} {quantity}건</span>
        </S.Bill>
        <S.Bill>
          <S.BillTitle>가격</S.BillTitle>
          <span>{(quantity ? price * parseInt(quantity) : price).toLocaleString()}원{type === "subscribe" ? " / 1개월마다" : ""}</span>
        </S.Bill>
      </S.BillContainer>
      <S.SubmitButton
        onClick={subscribe}
      >
        {type === "subscribe" ? "카드 등록하기" : "결제하기"}
      </S.SubmitButton>
    </S.Container>
  )
}

export default Payment;
