import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './pages/main';
import * as S from './styled'
import Payment from './pages/payment';
import Header from './components/header';
import { useEffect, useState } from 'react';
import { SuccessPage } from './pages/payment/success';
import { FailPage } from './pages/payment/fail';
import Footer from './components/footer';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import Information from './pages/information';
import Login from './pages/auth/login';
import { AuthProvider } from './hooks/useAuth';
import SignIn from './pages/auth/signin';
import Recover from './pages/auth/recover';
import Apply from './pages/place/apply';
import Dashboard from './pages/dashboard';
import MyPlace from './pages/myPlace';
import CardApplySuccess from './pages/payment/cardApplySuccess';
import { UsersProvider } from './hooks/useUsers';
import PlaceStore from './pages/place/store';

const App = () => {
  const [url, setUrl] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [currentPath, setCurrentPath] = useState<string>('/');

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Router>
      <UsersProvider>
        <AuthProvider>
          <S.Container>
            <Header width={width} setCurrentPath={setCurrentPath} />
            <Routes>
              <Route path="/" element={<Main width={width} />} />
              <Route path="/payment" element={<Payment url={url} setUrl={setUrl} phone={phone} setPhone={setPhone} />} />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/fail" element={<FailPage />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/information" element={<Information />} />
              <Route path="/login" element={<Login width={width} currentPath={currentPath} />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/recover" element={<Recover />} />
              <Route path="/apply" element={<Apply url={url} setUrl={setUrl} phone={phone} setPhone={setPhone} />} />
              <Route path="/dashboard" element={<Dashboard width={width} />} />
              <Route path="/my-place" element={<MyPlace />} />
              <Route path="/card-apply-success" element={<CardApplySuccess />} />
              <Route path="/store" element={<PlaceStore />} />
            </Routes>
            <Footer width={width} />
          </S.Container>
        </AuthProvider>
      </UsersProvider>
    </Router>
  );
}

export default App;
