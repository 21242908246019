import axios from 'axios';
import * as S from './styled';
import { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

interface FindIdProps {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  setIsFindId: React.Dispatch<React.SetStateAction<boolean | null>>;
  setFindId: React.Dispatch<React.SetStateAction<string>>;
}

export const FindId = ({ name, setName, phone, setPhone, setIsFindId, setFindId }: FindIdProps) => {
  const handleFindId = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!name || !phone) {
      alert("이름과 전화번호를 입력해주세요.");
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/auth/recover-id`, { "name": name, "phone_number": phone });
      console.log(response.data);
      setIsFindId(true);
      setFindId(response.data.id);
    } catch (error) {
      setIsFindId(false);
    }
  }
  return (
    <>
      <S.InputContainer>
        <S.InputWrapper>
          <S.Input type="text" placeholder="이름" value={name} onChange={(e) => setName(e.target.value)} />
        </S.InputWrapper>
      </S.InputContainer>
      <S.InputContainer>
        <S.InputWrapper>
          <S.Input type="text" placeholder="전화번호" value={phone} onChange={(e) => setPhone(e.target.value)} />
        </S.InputWrapper>
        <S.InputDescription>휴대폰 번호는 '-'를 제외하고 입력해주세요.</S.InputDescription>
      </S.InputContainer>
      <S.SubmitButton onClick={(e) => handleFindId(e)}>아이디 찾기</S.SubmitButton>
    </>
  )
}

interface RecoverPasswordBeforeAuthProps {
  userId: string;
  setUserId: React.Dispatch<React.SetStateAction<string>>;
  passwordUsername: string;
  setPasswordUsername: React.Dispatch<React.SetStateAction<string>>;
  setFindPasswordIndex: React.Dispatch<React.SetStateAction<number>>;
  setAuthNumber: React.Dispatch<React.SetStateAction<string>>;
}

export const RecoverPasswordBeforeAuth = ({ userId, setUserId, passwordUsername, setPasswordUsername, setFindPasswordIndex, setAuthNumber }: RecoverPasswordBeforeAuthProps) => {

  const handleAuth = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!userId || !passwordUsername) {
      alert("아이디와 이름을 입력해주세요.");
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/auth/recover-password-before-auth`, { "id": userId, "name": passwordUsername });
      console.log(response.data);
      setFindPasswordIndex(1);
      setAuthNumber(response.data.auth_number);
    } catch (error) {
      alert("아이디와 이름을 확인해주세요.");
    }
  }

  return (
    <>
      <div>아이디에 저장된 전화번호로 인증번호를 보내드립니다.</div>
      <S.InputContainer>
        <S.InputWrapper>
          <S.Input type="text" placeholder="아이디" value={userId} onChange={(e) => setUserId(e.target.value)} />
        </S.InputWrapper>
      </S.InputContainer>
      <S.InputContainer>
        <S.InputWrapper>
          <S.Input type="text" placeholder="이름" value={passwordUsername} onChange={(e) => setPasswordUsername(e.target.value)} />
        </S.InputWrapper>
      </S.InputContainer>
      <S.SubmitButton onClick={handleAuth}>문자 인증</S.SubmitButton>
    </>
  )
}

const remainingTimeFormat = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
}

interface RecoverPasswordAfterAuthProps {
  authNumber: string;
  setFindPasswordIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const RecoverPasswordAfterAuth = ({ authNumber, setFindPasswordIndex }: RecoverPasswordAfterAuthProps) => {
  const [currentAuthNumber, setCurrentAuthNumber] = useState<string>('');
  const [remainingTime, setRemainingTime] = useState<number>(300);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [notMatch, setNotMatch] = useState<boolean>(false);

  const handleAuth = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (remainingTime <= 0) {
      alert("인증번호가 만료되었습니다.");
      setFindPasswordIndex(0);
      return;
    }
    if (currentAuthNumber.toString() !== authNumber.toString()) {
      alert("인증번호가 일치하지 않습니다.");
      setNotMatch(true);
      return;
    }
    clearInterval(timer!);
    setRemainingTime(300);
    setFindPasswordIndex(2);
  }

  useEffect(() => {
    if (timer) {
      clearInterval(timer);
    }
    const newTimer = setInterval(() => {
      setRemainingTime((prev) => prev - 1);
    }, 1000);
    setTimer(newTimer);
  }, []);

  useEffect(() => {
    if (remainingTime <= 0) {
      clearInterval(timer!);
    }
  }, [remainingTime, timer]);

  return (
    <>
      <div>인증번호를 입력해주세요.</div>
      <S.InputContainer>
        <S.InputWrapper>
          <S.Input type="text" placeholder="인증번호" value={currentAuthNumber} onChange={(e) => setCurrentAuthNumber(e.target.value)} />
          <S.Timer>{remainingTimeFormat(remainingTime)}</S.Timer>
        </S.InputWrapper>
      </S.InputContainer>
      {/* {notMatch && <S.SubmitButton >인증번호 재전송</S.SubmitButton>} */}
      <S.SubmitButton onClick={(e) => handleAuth(e)}>확인</S.SubmitButton>
    </>
  )
}

const validatePassword = (password: string): boolean => {
  // 비밀번호는 문자, 숫자, 특수문자(~!@#$%^&*)의 조합 10 ~ 16자리로 입력이 가능합니다.
  const passwordRegExp = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[~!@#$%^&*])[a-zA-Z0-9~!@#$%^&*]{10,16}$/;
  return passwordRegExp.test(password);
}

interface RecoverPasswordProps {
  userId: string;
  name: string;
  setFindPasswordIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const RecoverPassword = ({ userId, name, setFindPasswordIndex }: RecoverPasswordProps) => {
  const [password, setPassword] = useState<string>('');
  const [rePassword, setRePassword] = useState<string>('');
  const [isPasswordValid, setIsPasswordValid] = useState<boolean | null>(null);
  const [isRePasswordValid, setIsRePasswordValid] = useState<boolean | null>(null);
  const navigate = useNavigate();

  const handleRecoverPassword = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log(userId, name, password, rePassword);
    if (!isPasswordValid || !isRePasswordValid) {
      alert("비밀번호를 확인해주세요.");
      return;
    }
    try {
      const response = await axios.post(`${API_URL}/auth/recover-password`, { "id": userId, "name": name, "password": password });
      console.log(response.data);
      alert("비밀번호가 변경되었습니다.");
      navigate("/login");
    } catch (error) {
      alert("비밀번호 변경에 실패했습니다. 다시 시도해 주세요.");
      setFindPasswordIndex(0);
    }
  }

  useEffect(() => {
    if (password === "") {
      setIsPasswordValid(null);
    } else {
      setIsPasswordValid(validatePassword(password));
    }
  }, [password]);

  useEffect(() => {
    if (rePassword === "") {
      setIsRePasswordValid(null);
    } else {
      setIsRePasswordValid(password === rePassword);
    }
  }, [rePassword, password]);

  return (
    <>
      <div>새로 설정할 비밀번호를 입력해주세요.</div>
      <S.InputContainer>
        <S.InputWrapper>
          <S.Input type="password" placeholder="비밀번호 입력" value={password} onChange={(e) => setPassword(e.target.value)} />
          {isPasswordValid === false ? <CloseIcon style={{ "color": "red" }} /> : isPasswordValid === true ? <CheckIcon style={{ "color": "green" }} /> : null}
        </S.InputWrapper>
        <S.InputDescription>비밀번호는 문자, 숫자, 특수문자(~!@#$%^&*)의 조합
          10 ~ 16자리로 입력이 가능합니다.</S.InputDescription>
      </S.InputContainer>
      <S.InputContainer>
        <S.InputWrapper>
          <S.Input type="password" placeholder="비밀번호 재입력" value={rePassword} onChange={(e) => setRePassword(e.target.value)} />
          {isRePasswordValid === false ? <CloseIcon style={{ "color": "red" }} /> : isRePasswordValid === true ? <CheckIcon style={{ "color": "green" }} /> : null}
        </S.InputWrapper>
      </S.InputContainer>
      <S.SubmitButton onClick={(e) => handleRecoverPassword(e)}>비밀번호 변경</S.SubmitButton>
    </>
  )

}
