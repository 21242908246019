import * as S from './styled';
import Colors from '../../styles/colors';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Logo from '../../assets/images/logo.png';
import { useAuth } from '../../hooks/useAuth';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import QuizIcon from '@mui/icons-material/Quiz';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface MobileMenuItemProps {
  icon: JSX.Element;
  text: string;
  href: string;
}

const APP_DOMAIN = process.env.REACT_APP_DOMAIN;

export const MobileMenuItem = ({ icon, text, href }: MobileMenuItemProps) => {
  return (
    <S.MobileMenuItem href={href}>
      <S.MobileMenuItemLeft>
        {icon}
        <div>{text}</div>
      </S.MobileMenuItemLeft>
      <KeyboardArrowRightIcon style={{ "fontSize": "20px", "color": `${Colors.charcole}` }} />
    </S.MobileMenuItem>
  )
}

export const DesktopHeader = () => {
  const auth = useAuth();
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const path = window.location.pathname;
  const isDashboardPath = path === '/dashboard';
  const isAddApplyPath = path === '/apply';
  const isStorePath = path === '/store';
  const navigate = useNavigate();
  const clickLogo = (goTo: string) => {
    window.location.href = goTo;
  }

  useEffect(() => {
    console.log("DesktopHeader useEffect", auth.state);
    setIsLogin(auth.state.isAuthenticated);
  }, [auth.state.isAuthenticated]);

  useEffect(() => {
    console.log("DesktopHeader useEffect userPlaces", auth.state.userPlaces);
  }, [auth.state.userPlaces]);

  return (
    <>
      <S.Logo src={Logo} alt="logo" onClick={() => clickLogo('/')} />
      {auth.state.userPlaces && auth.state.userPlaces.length > 0 ?
        <S.Menu>
          <S.TextButton onClick={() => navigate('/store')} style={{ "color": `${isStorePath ? Colors.main : Colors.deepDark}`, "fontWeight": `${isStorePath ? "bold" : "medium"}`, cursor: "pointer" }}>구매</S.TextButton>
          <S.TextButton onClick={() => navigate('/dashboard')} style={{ "color": `${isDashboardPath ? Colors.main : Colors.deepDark}`, "fontWeight": `${isDashboardPath ? "bold" : "medium"}`, cursor: "pointer" }}>대시보드</S.TextButton>
          <S.TextButton onClick={() => navigate('/apply')} style={{ "color": `${isAddApplyPath ? Colors.main : Colors.deepDark}`, "fontWeight": `${isAddApplyPath ? "bold" : "medium"}`, cursor: "pointer" }}>추가등록</S.TextButton>
          {isLogin ?
            <S.Button $backgroundColor={Colors.main} onClick={auth.logout}>로그아웃</S.Button>
            :
            <S.Button href="/login" $backgroundColor={Colors.main}>
              로그인
            </S.Button>
          }
          {auth.state && auth.state.admin === 0 &&
            <S.Button href="https://pf.kakao.com/_PxoxgkG" $backgroundColor={Colors.charcole} target="_blank">
              문의하기
            </S.Button>
          }
        </S.Menu>
        :
        <S.Menu>
          <S.TextButton onClick={() => navigate('/store')} style={{ "color": `${isStorePath ? Colors.main : Colors.deepDark}`, "fontWeight": `${isStorePath ? "bold" : "medium"}`, cursor: "pointer" }}>구매</S.TextButton>
          {auth.state && auth.state.admin > 0 && <S.TextButton onClick={() => navigate('/dashboard')} style={{ "color": `${isDashboardPath ? Colors.main : Colors.deepDark}`, "fontWeight": `${isDashboardPath ? "bold" : "medium"}` }}>대시보드</S.TextButton>}
          {/* <S.TextButton href="/information" style={{ "color": `${Colors.deepDark}` }}>서비스 소개</S.TextButton> */}
          {isLogin ?
            <>
              <S.TextButton href="/apply" style={{ "color": `${Colors.deepDark}` }}>플레이스 등록</S.TextButton>
              <S.Button $backgroundColor={Colors.main} onClick={auth.logout}>로그아웃</S.Button>
            </>
            :
            <S.Button href="/login" $backgroundColor={Colors.main}>
              로그인
            </S.Button>
          }
          {auth.state && auth.state.admin === 0 &&
            <S.Button href="https://pf.kakao.com/_PxoxgkG" $backgroundColor={Colors.charcole} target="_blank">
              문의하기
            </S.Button>
          }
        </S.Menu>
      }
    </>
  )
}

interface MobileOpenMenuHeaderProps {
  width: number;
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MobileOpenMenuHeader = ({ width, setOpenMenu }: MobileOpenMenuHeaderProps) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    setOpenMenu(false);
    auth.logout();
  };

  const handleLogin = () => {
    setOpenMenu(false);
    navigate('/login');
  };

  const handleCopyLink = () => {
    const linkToCopy = `${APP_DOMAIN}/apply?sales=${auth.state.userId}`
    navigator.clipboard.writeText(linkToCopy).then(() => {
      alert('링크가 클립보드에 복사되었습니다!');
    }).catch(err => {
      console.error('클립보드 복사 실패:', err);
    });
  };

  return (
    <>
      <S.Backdrop onClick={() => setOpenMenu(false)} />
      <S.MobileMenuContainer $width={width}>
        <div>
          <CloseIcon style={{ "fontSize": "30px", "color": `${Colors.charcole}`, "marginTop": "15px" }}
            onClick={() => setOpenMenu(false)} />
          <S.MobileMenuTop>
            <S.Logo src={Logo} alt="logo" />
            <S.MobileMenuMessage>
              {auth.state.isAuthenticated === false ? (
                <>
                  <div>안녕하세요!</div>
                  <div>지금 리뷰알리미에 로그인하고</div>
                  <div>쉽고 간편하게 리뷰를</div>
                  <div>관리해보세요!</div>
                </>
              ) : (
                <>
                  <div>안녕하세요, {auth.state.userName}님!</div>
                  {auth.state.userPlaces && auth.state.admin === 0 && auth.state.userPlaces.length === 0 && (
                    <>
                      <div>지금 플레이스를 등록하고</div>
                      <div>쉽고 간편하게 리뷰를</div>
                      <div>관리해보세요!</div>
                    </>
                  )}
                  {auth.state && auth.state.admin > 0 && (
                    <>
                      <div>관리자 모드로 이용중입니다.</div>
                      <div style={{ "display": "flex", "alignItems": "center", "justifyContent": "space-around" }}>
                        <p>영업 링크 복사</p>
                        <ContentCopyIcon style={{ "fontSize": "20px", "color": `${Colors.charcole}`, "cursor": "pointer" }} onClick={handleCopyLink} />
                      </div>
                    </>
                  )}
                </>
              )}
            </S.MobileMenuMessage>
            {auth.state.isAuthenticated === false &&
              <S.Button onClick={handleLogin} $backgroundColor={Colors.main}>
                로그인
              </S.Button>
            }
            {((auth.state.userPlaces && auth.state.userPlaces.length > 0) || (auth.state.admin > 0)) &&
              <S.MobileMenuList>
                <MobileMenuItem icon={<DashboardIcon style={{ "fontSize": "30px", "color": `${Colors.charcole}` }} />} text="대시보드" href="/dashboard" />
              </S.MobileMenuList>
            }
            <div />
          </S.MobileMenuTop>
          <S.MobileMenuList>
            <MobileMenuItem icon={<HomeIcon style={{ "fontSize": "30px", "color": `${Colors.charcole}` }} />} text="홈" href="/" />
            <MobileMenuItem icon={<HomeIcon style={{ "fontSize": "30px", "color": `${Colors.charcole}` }} />} text="구매" href="/store" />
            {/* <MobileMenuItem icon={<InfoIcon style={{ "fontSize": "30px", "color": `${Colors.charcole}` }} />} text="서비스 소개" href="/information" /> */}
            <MobileMenuItem icon={<QuizIcon style={{ "fontSize": "30px", "color": `${Colors.charcole}` }} />} text="자주 묻는 질문" href="https://pf.kakao.com/_PxoxgkG" />
            <MobileMenuItem icon={<QuestionAnswerIcon style={{ "fontSize": "30px", "color": `${Colors.charcole}` }} />} text="문의하기" href="https://pf.kakao.com/_PxoxgkG" />
          </S.MobileMenuList>
        </div>
        {auth.state.isAuthenticated &&
          <S.MobileLogoutButton onClick={handleLogout}>
            <LogoutIcon style={{ "fontSize": "30px", "color": `${Colors.charcole}` }} />
            <div>
              로그아웃
            </div>
          </S.MobileLogoutButton>
        }
      </S.MobileMenuContainer>
    </>
  )
}
