import { useNavigate } from "react-router-dom"
import * as S from "./styled"
import { useAuth } from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../../assets/images/loading.gif";

const API_URL = process.env.REACT_APP_API_URL;

export function SuccessPage() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const { dispatch, loadApply, loadIdAndName, syncUser } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const customerKey = urlParams.get("customerKey");
  const authKey = urlParams.get("authKey");
  const sales = urlParams.get("sales");
  const type = urlParams.get("type");
  const quantity = urlParams.get("quantity");
  const paymentKey = urlParams.get("paymentKey");

  useEffect(() => {
    const applyPlace = async () => {
      const { id, userId, name } = loadIdAndName();
      if (type === "subscribe") {
        try {
          console.log("request elements:", userId, name, customerKey, authKey, sales, type, quantity);
          const form = {
            id: userId,
            name: name,
            customer_key: customerKey,
            auth_key: authKey,
            sales: sales ? sales : "",
            type: type ? type : "",
            quantity: quantity ? parseInt(quantity) : 0
          }
          console.log("form:", form);
          const response = await axios.post(`${API_URL}/users/apply`, form);
          console.log(response.data);
          setLoading(false);
          dispatch({ type: 'SET_APPLY', payload: null });
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          const form = {
            user_id: userId,
            name: name,
            sales: sales ? sales : "",
            quantity: quantity ? parseInt(quantity) : 0,
            payment_key: paymentKey ? paymentKey : ""
          }
          console.log("form:", form);
          const response = await axios.post(`${API_URL}/users/month-payment`, form);
          console.log(response.data);
          setLoading(false);
          dispatch({ type: 'SET_APPLY', payload: null });
        } catch (error) {
          console.error(error);
        }
      }
    };

    applyPlace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = async () => {
    await syncUser();
    navigate("/dashboard");
  }

  return (
    <S.Container>
      <h1>결제 성공</h1>
      {loading ?
        <>
          <img src={Loading} alt="loading" />
          <h3>결제 진행 중 입니다. 잠시만 기다려주세요.</h3>
        </>
        :
        <>
          <h3>서비스 구매가 완료되었습니다.</h3>
          <h3>확인 버튼을 누르시면 대시보드로 이동합니다.</h3>
          <S.SubmitButton onClick={handleConfirm}>확인</S.SubmitButton>
        </>
      }
    </S.Container>
  )
}
