import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  gap: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
`;

export const DetailTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 90%;
  height: 500px;
  padding: 20px;
  border: 1px solid red;
`;

export const Image = styled.img`
  width: 80%;
  height: auto;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContactButton = styled.a`
  width: 90px;
  height: 40px;
  background-color: #363636;
  color: white;
  border: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  gap: 30px;
  padding-top: 50px;
  padding-bottom: 50px;
`;
