import React, { createContext, useReducer, useContext, ReactNode, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useUsers } from '../useUsers';

const API_URL = process.env.REACT_APP_API_URL;

type Apply = {
  url: string;
  placeName: string;
  phone: string;
}

type Review = {
  auth_method: string;
  author: string;
  detected_at: string;
  gemini_result: number;
  place_id: number;
  place_name: string;
  review_content: string;
  url: string | null;
  visit_count: number;
  visit_date: string;
}

type Card = {
  issuer_code: string;
  acquirer_code: string;
  number: string;
  card_type: string;
  owner_type: string;
}

type Place = {
  url: string;
  place_name: string;
  user_id: string;
  phone_number: string;
  total_reviews: number;
  negative_reviews: number;
  reviews: Review[];
  customer_key: string;
  auth_key: string;
  billing_key: string;
  card: Card | null;
  next_billing_date: string | null;
}

// 로그인 상태의 타입 정의
type AuthState = {
  id: string | null;
  isAuthenticated: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  userId: string | null;
  userName: string | null;
  userPhone: string | null;
  userPlaces: Place[] | null;
  apply: Apply | null;
  admin: number;
  grade: number;
};

// 액션 타입 정의
type AuthAction =
  | { type: 'LOGIN'; payload: { id: string | null, accessToken: string; refreshToken: string; userId: string; userName: string; userPhone: string; userPlaces: Place[], apply: Apply | null, admin: number, grade: number } }
  | { type: 'LOGOUT' }
  | { type: 'SET_APPLY'; payload: Apply | null }
  | { type: 'SET_USER'; payload: { id: string | null, userName: string; userPhone: string; userPlaces: Place[] } }
  | { type: 'REFRESH_TOKEN'; payload: { accessToken: string } }
  | { type: 'SET_USER_PLACES'; payload: Place[] };

// 초기 상태
const initialState: AuthState = {
  id: null,
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
  userId: null,
  userName: null,
  userPhone: null,
  userPlaces: null,
  apply: null,
  admin: 0,
  grade: 0,
};

// 리듀서 함수
const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        userId: action.payload.userId,
        userName: action.payload.userName,
        userPhone: action.payload.userPhone,
        userPlaces: action.payload.userPlaces,
        apply: action.payload.apply,
        admin: action.payload.admin,
        grade: action.payload.grade,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        refreshToken: null,
        userId: null,
        userName: null,
        userPhone: null,
        userPlaces: null,
        apply: null,
        admin: 0,
        grade: 0,
      };
    case 'SET_APPLY':
      const updateState = {
        ...state,
        apply: action.payload,
      };
      localStorage.setItem('auth', JSON.stringify(updateState));
      return updateState;
    case 'SET_USER':
      return {
        ...state,
        id: action.payload.id,
        userName: action.payload.userName,
        userPhone: action.payload.userPhone,
        userPlaces: action.payload.userPlaces,
      };
    case 'REFRESH_TOKEN':
      const updatedState = {
        ...state,
        accessToken: action.payload.accessToken,
      };
      localStorage.setItem('auth', JSON.stringify(updatedState));
      return updatedState;
    case 'SET_USER_PLACES':
      const placeUpdatedState = {
        ...state,
        userPlaces: action.payload,
      };
      localStorage.setItem('auth', JSON.stringify(placeUpdatedState));
      return placeUpdatedState;
    default:
      return state;
  }
};

// Context 생성
const AuthContext = createContext<{
  state: AuthState;
  dispatch: React.Dispatch<AuthAction>;
  login: (id: string, password: string) => Promise<void>;
  logout: () => void;
  applyPlace: (url: string, placeName: string, phone: string) => void;
  loadApply: () => Apply | null;
  loadIdAndName: () => { id: string | null, userId: string | null, name: string | null };
  syncUser: () => Promise<void>;
  refreshToken: () => Promise<void>;
  changePhone: (phone: string, user_id: string, place_name: string, url: string) => Promise<void>
}>({
  state: initialState,
  dispatch: () => null,
  login: async () => { },
  logout: () => { },
  applyPlace: () => { },
  loadApply: () => null,
  loadIdAndName: () => { return { id: null, userId: null, name: null } },
  syncUser: async () => { },
  refreshToken: async () => { },
  changePhone: async () => { },
});

// Context Provider 컴포넌트
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const users = useUsers();
  const navigate = useNavigate();

  useEffect(() => {
    const storedState = localStorage.getItem('auth');
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      dispatch({
        type: 'LOGIN',
        payload: {
          id: parsedState.id,
          accessToken: parsedState.accessToken,
          refreshToken: parsedState.refreshToken,
          userId: parsedState.userId,
          userName: parsedState.userName,
          userPhone: parsedState.userPhone,
          userPlaces: parsedState.userPlaces,
          apply: parsedState.apply,
          admin: parsedState.admin,
          grade: parsedState.grade,
        }
      });
    }
  }, []);

  const login = async (requestId: string, password: string) => {
    try {
      const response = await axios.post(`${API_URL}/auth/login`, {
        id: requestId,
        password: password,
      });
      console.log("로그인 성공:", response.data);
      const { id, access_token, refresh_token, user_id, name, phone, places, admin, grade } = response.data;
      const payload = {
        id: id,
        accessToken: access_token,
        refreshToken: refresh_token,
        userId: user_id,
        userName: name,
        userPhone: phone,
        userPlaces: places,
        apply: null,
        admin: admin,
        grade: grade,
      }
      dispatch({
        type: 'LOGIN',
        payload: payload,
      });
      localStorage.setItem('auth', JSON.stringify(payload));
    } catch (error) {
      console.error("로그인 실패:", error);
      throw error; // 로그인 컴포넌트에서 에러를 처리할 수 있도록 예외를 던집니다.
    }
    await syncUser();
  };

  const logout = useCallback(() => {
    localStorage.removeItem('auth');
    dispatch({ type: 'LOGOUT' });
    navigate('/');
  }, [navigate]);

  const applyPlace = (url: string, placeName: string, phone: string) => {
    const payload = {
      url: url,
      placeName: placeName,
      phone: phone,
    };
    dispatch({ type: 'SET_APPLY', payload: payload });
  };

  const loadIdAndName = () => {
    const storedState = localStorage.getItem('auth');
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      return { id: parsedState.id, userId: parsedState.userId, name: parsedState.userName };
    }
    return { id: null, userId: null, name: null };
  }

  const loadApply = () => {
    const storedState = localStorage.getItem('auth');
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      return parsedState.apply;
    }
    return null;
  }

  const changePhone = async (phone: string, user_id: string, place_name: string, url: string) => {
    try {
      const response = await axios.post(`${API_URL}/place/change-phone-number`, {
        user_id: user_id,
        place_name: place_name,
        phone_number: phone,
        url: url,
      });
      console.log("전화번호 변경 성공:", response.data);
    } catch (error) {
      console.error("전화번호 변경 실패:", error);
    }
    syncUser();
  }

  // 서버와 로컬스토리지의 정보 동기화
  const syncUser = async () => {
    const storedState = localStorage.getItem('auth');
    if (storedState) {
      const parsedState = JSON.parse(storedState);
      try {
        const response = await axios.get(`${API_URL}/users/user?user_id=${parsedState.userId}`);
        const { name, phone, places, id } = response.data;
        dispatch({
          type: 'SET_USER',
          payload: {
            id: id,
            userName: name,
            userPhone: phone,
            userPlaces: places,
          }
        });
        const placeResponse = await axios.post(`${API_URL}/place/get-my-places`, { user_id: parsedState.userId });
        console.log("사용자 정보 동기화 성공:", placeResponse.data);
        dispatch({
          type: 'SET_USER_PLACES',
          payload: placeResponse.data,
        });
        await users.getUsers(parsedState.admin);
      } catch (error) {
        console.error("사용자 정보 동기화 실패:", error);
      }
    }
  }

  const refreshToken = useCallback(async () => {
    try {
      const response = await axios.post(`${API_URL}/auth/refresh-token`, {
        refresh_token: state.refreshToken,
      });
      const { access_token } = response.data;
      dispatch({
        type: 'REFRESH_TOKEN',
        payload: { accessToken: access_token },
      });
      const updatedState = { ...state, accessToken: access_token };
      localStorage.setItem('auth', JSON.stringify(updatedState));
    } catch (error) {
      console.error("토큰 갱신 실패:", error);
      logout();
    }
  }, [logout, state]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (state.isAuthenticated) {
        refreshToken();
      }
    }, 15 * 60 * 1000); // 15분마다 토큰 갱신

    return () => clearInterval(interval);
  }, [refreshToken]);

  return (
    <AuthContext.Provider value={{ state, dispatch, login, logout, applyPlace, loadApply, loadIdAndName, syncUser, refreshToken, changePhone }}>
      {children}
    </AuthContext.Provider>
  );
};

// 커스텀 훅 생성
export const useAuth = () => useContext(AuthContext);
