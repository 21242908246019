import * as S from './styled';
import Logo from '../../../assets/images/logo.png';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

interface LoginProps {
  width: number;
  currentPath: string;
}

const Login: React.FC<LoginProps> = ({ width, currentPath }: LoginProps) => {
  const [id, setId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();

    if (id === '' || password === '') {
      alert('아이디와 비밀번호를 입력해주세요.');
      return;
    }

    try {
      await login(id, password);
      navigate(currentPath);
    } catch (error) {
      alert('로그인 실패');
    }
  }

  useEffect(() => {
    console.log("currentPath:", currentPath);
  })

  return (
    <S.Container>
      {width > 480 ? null : <S.Logo src={Logo} alt="logo" />}
      <S.Title>로그인</S.Title>
      <S.Form>
        <S.Input
          type="text"
          placeholder="아이디"
          value={id}
          onChange={(e) => setId(e.target.value)}
        />
        <S.Input
          type="password"
          placeholder="비밀번호"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <S.SubmitButton onClick={(e) => handleLogin(e)}>로그인</S.SubmitButton>
        <S.OtherOptions>
          <S.OtherOption onClick={() => navigate("/recover")}>아이디/비밀번호 찾기</S.OtherOption>
          <S.OtherOption onClick={() => navigate("/signin")}>회원가입</S.OtherOption>
        </S.OtherOptions>
      </S.Form>
    </S.Container>
  );
};

export default Login;
