import React, { useEffect, useState } from 'react';
import * as S from './styled';
import { useAuth } from '../../hooks/useAuth';
import Colors from '../../styles/colors';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { format, subDays } from 'date-fns';
import { Box, Button, Dialog, Divider, Modal, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useUsers } from '../../hooks/useUsers';
import axios from 'axios';
import StorefrontIcon from '@mui/icons-material/Storefront';
import MessageIcon from '@mui/icons-material/Message';
import Loading from "../../assets/images/loading.gif";

const API_URL = process.env.REACT_APP_API_URL;

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = (isMobile: boolean) => {
  return ({
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: isMobile ? '최근 4일간 리뷰 수' : '최근 1주일간 리뷰 수',
      },
    },
  });
};

const APP_DOMAIN = process.env.REACT_APP_DOMAIN;

const getRecentWeekLabels = (isMobile: boolean) => {
  const labels = [];
  if (isMobile) {
    for (let i = 3; i >= 0; i--) {
      labels.push(format(subDays(new Date(), i), 'MM-dd'));
    }
    return labels;
  }
  for (let i = 6; i >= 0; i--) {
    labels.push(format(subDays(new Date(), i), 'MM-dd'));
  }
  return labels;
};

interface StoreListProps {
  width: number;
}


export const StoreList = ({ width }: StoreListProps) => {
  const [placeList, setPlaceList] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [placeName, setPlaceName] = useState<string>('');
  const [chartData, setChartData] = useState<any>(null);
  const [openManagement, setOpenManagement] = useState(false);
  const [modalStyle, setModalStyle] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [openApply, setOpenApply] = useState<string | null>(null);
  const [index, setIndex] = useState<number>(0);
  const [url, setUrl] = useState<string>('');
  const [storeName, setStoreName] = useState<string>('');
  const { changePhone, state, applyPlace } = useAuth();

  const handleOpen = () => {
    setPhone('');
    setOpenManagement(true);
  }

  useEffect(() => {
    if (state.isAuthenticated && state.userPlaces && state.userPlaces.length > 0) {
      setPlaceList(state.userPlaces);
      setSelectedIndex(0);
    }
  }, [state.isAuthenticated, state.userPlaces]);

  const handleItemClick = (index: number) => {
    setSelectedIndex(index);
  };

  const handlePlaceNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlaceName(e.target.value);
    if (!state.userPlaces || state.userPlaces.length === 0) return;
    console.log(e.target.value);
    const filteredList = state.userPlaces.filter((place) =>
      place.place_name.includes(e.target.value)
    );
    setPlaceList(filteredList);
  };

  const handleCancelSubscribe = () => {
    if (selectedIndex === null) return;
    setModalStyle('subscribe');
    // const placeName = placeList[selectedIndex].place_name;
    // console.log(`구독 취소: ${placeName}`, placeList[selectedIndex]);
  }

  const validatePhone = (phone: string): boolean => {
    // 휴대폰 번호는 '-'를 제외하고 입력해주세요. + 010으로 시작하는 11자리 숫자
    const phoneRegExp = /^010\d{8}$/;
    return phoneRegExp.test(phone);
  }

  const handleChangePhone = async () => {
    if (selectedIndex === null) return;
    if (!validatePhone(phone)) {
      alert("휴대폰 번호를 확인해주세요.");
      return;
    }
    const url = placeList[selectedIndex].url;
    const placeName = placeList[selectedIndex].place_name;
    const user_id = placeList[selectedIndex].user_id;
    const phone_number = phone;
    try {
      await changePhone(phone_number, user_id, placeName, url);
      alert("전화번호 변경이 완료되었습니다.");
      setOpenManagement(false);
      window.location.reload();
    } catch (error) {
      console.error("전화번호 변경 실패:", error);
    }
  }

  const handleValidateUrl = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (url === '') {
      alert('네이버 플레이스 URL을 입력해주세요.');
      return;
    }
    setIndex(1);
    try {
      const response = await axios.post(`${API_URL}/place/validate-url`, { url });
      setStoreName(response.data);
      if (state.userPlaces && state.userPlaces.some(place => place.url === url && place.place_name === response.data)) {
        alert('이미 등록된 URL입니다.');
        setIndex(0); // 중복된 경우 인덱스를 초기화
        return;
      }
      setIndex(2);
    } catch (error) {
      alert('올바르지 않은 URL입니다. 다시 확인해주세요.');
      setIndex(0);
    }
  }

  const handleSendSms = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (phone === '') {
      alert('휴대폰 번호를 입력해주세요.');
      return;
    }
    if (!validatePhone(phone)) {
      alert('휴대폰 번호를 확인해주세요.');
      return;
    }
    try {
      // await axios.post(`${API_URL}/place/send-sms-test`, { "phone_number": phone });
      applyPlace(url, storeName, phone);
      setIndex(4);
    } catch (error) {
      alert('문자 전송에 실패했습니다. 다시 시도해주세요.');
      setIndex(3);
    }
  }

  const handleApply = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (selectedIndex === null) return;
    const place = placeList.find((place) => place.id === openApply);
    console.log("place:", place);
    const form = {
      user_id: place.user_id,
      place_id: place.id,
      place_name: storeName,
      url: url,
      phone_number: phone,
    };
    try {
      await axios.post(`${API_URL}/place/apply-monthly`, form);
      alert("플레이스 등록이 완료되었습니다.");
    } catch (error) {
      console.error("플레이스 등록 실패:", error);
    }
    setUrl('');
    setPhone('');
    setStoreName('');
    setOpenApply(null);
    window.location.reload();
  }

  useEffect(() => {
    if (selectedIndex !== null) {
      const selectedPlace = placeList[selectedIndex];
      const labels = getRecentWeekLabels(width <= 480);
      if (!selectedPlace.reviews) return;
      const reviewCounts = labels.map(label => {
        return selectedPlace.reviews.filter((review: any) => format(new Date(review.detected_at), 'MM-dd') === label).length;
      });
      const negativeReviewCounts = labels.map(label => {
        return selectedPlace.reviews.filter((review: any) => format(new Date(review.detected_at), 'MM-dd') === label && (review.gemini_result === 3 || review.gemini_result === 4)).length;
      });

      setChartData({
        labels,
        datasets: [
          {
            label: '총 리뷰 수',
            data: reviewCounts,
            backgroundColor: `${Colors.deepDark}`,
          },
          {
            label: '부정적 리뷰 수',
            data: negativeReviewCounts,
            backgroundColor: `${Colors.main}`,
          },
        ],
      });
    }
  }, [selectedIndex, placeList]);

  return (
    <S.ListContainer style={{ "justifyContent": "space-between" }}>
      <div>
        <S.ListHeader>
          <S.HeaderTitle style={{ "justifyContent": "flex-start" }}>매장 목록</S.HeaderTitle>
          <S.SearchInput
            placeholder="매장 검색"
            value={placeName}
            onChange={(e) => handlePlaceNameChange(e)}
          />
        </S.ListHeader>
        {/* 여기에 매장 목록 데이터를 렌더링합니다 */}
        <S.ItemHeader>
          <S.HeaderTitle style={{ "justifyContent": "flex-start" }}>매장 이름</S.HeaderTitle>
          <S.HeaderTitle>감지된 리뷰 수</S.HeaderTitle>
          <S.HeaderTitle>부정 리뷰 수</S.HeaderTitle>
        </S.ItemHeader>
        <S.ListContent>
          {placeList.map((place, index) => {
            const isSelected = selectedIndex === index;
            return (
              <S.Item
                key={index}
                onClick={() => handleItemClick(index)}
                $isSelected={selectedIndex === index}
              >
                <S.ItemContent>
                  <S.ItemName>{place.place_name ? place.place_name : "플레이스를 등록해주세요."}</S.ItemName>
                  <S.ItemCounts>{place.total_reviews}</S.ItemCounts>
                  <S.ItemCounts style={{ "color": `${Colors.main}` }}>{place.negative_reviews}</S.ItemCounts>
                </S.ItemContent>
                {isSelected && (
                  <S.ReviewContent style={{ "gap": "5px" }}>
                    {
                      place.type === 'subscribe' &&
                      <S.ItemContent>
                        <S.ItemName>다음 결제일</S.ItemName>
                        <S.ItemCounts>{place.next_billing_date === null ? "구독 취소됨" : format(new Date(place.next_billing_date), "yyyy-MM-dd")}</S.ItemCounts>
                      </S.ItemContent>
                    }
                    {
                      place.type === 'month' &&
                      <S.ItemContent>
                        <S.ItemName>만료일</S.ItemName>
                        <S.ItemCounts>{place.next_billing_date === null ? "기한 만료" : place.url === null ? `${format(new Date(place.next_billing_date), "yyyy-MM-dd")} 까지 등록해주세요.` : format(new Date(place.next_billing_date), "yyyy-MM-dd")}</S.ItemCounts>
                      </S.ItemContent>
                    }
                    <S.ItemContent>
                      <S.ItemName>전화번호</S.ItemName>
                      <S.ItemCounts>{place.phone_number}</S.ItemCounts>
                    </S.ItemContent>
                    <S.ItemContent>
                      <S.ItemName>URL</S.ItemName>
                      <S.ItemCounts>
                        <a href={place.url} target="_blank" rel="noopener noreferrer">{place.url}</a>
                      </S.ItemCounts>
                    </S.ItemContent>
                    {state.userId === place.user_id ?
                      <Button
                        sx={{
                          color: `${Colors.main}`,
                          fontWeight: 'bold',
                          '&:hover': {
                            backgroundColor: 'transparent',  // 배경색이 변경되지 않도록 설정
                            color: `${Colors.main}`,
                          },
                        }}
                        onClick={place.place_name ? handleOpen : () => setOpenApply(place.id)}
                      >{place.place_name ? "관리" : "등록"}</Button>
                      :
                      <S.ItemContent>
                        <S.ItemName>고객</S.ItemName>
                        <S.ItemCounts>
                          <S.ItemCounts>{place.user_id}</S.ItemCounts>
                        </S.ItemCounts>
                      </S.ItemContent>
                    }
                  </S.ReviewContent>
                )}
              </S.Item>
            );
          })}
          <Modal
            open={openManagement}
            onClose={() => setOpenManagement(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={S.modalStyle}>
              {modalStyle === '' ?
                <>
                  <h3>관리</h3>
                  <S.ModalButton onClick={() => setModalStyle('subscribe')}>구독 취소</S.ModalButton>
                  <S.ModalButton onClick={() => setModalStyle('phone')}>전화번호 변경</S.ModalButton>
                  <S.ModalButton onClick={() => setOpenManagement(false)} style={{ "backgroundColor": `${Colors.deepDark}` }}>닫기</S.ModalButton>
                </> : modalStyle === 'subscribe' ?
                  <>
                    <h3>구독 취소</h3>
                    <div>정말로 구독을 취소하시겠습니까?</div>
                    <div>취소하시면 다음 정기 결제일 이후 기존의 리뷰 데이터가 삭제됩니다.</div>
                    <S.ModalButton>확인</S.ModalButton>
                    <S.ModalButton onClick={() => setModalStyle('')} style={{ "backgroundColor": `${Colors.deepDark}` }}>취소</S.ModalButton>
                  </> :
                  <>
                    <h3>전화번호 변경</h3>
                    <div>변경할 전화번호를 입력해주세요.</div>
                    <S.InputContainer>
                      <S.InputWrapper>
                        <S.Input type="text" placeholder="휴대폰 번호 입력" value={phone} onChange={(e) => setPhone(e.target.value)} />
                      </S.InputWrapper>
                      <S.InputDescription>휴대폰 번호는 '-'를 제외하고 입력해주세요.</S.InputDescription>
                    </S.InputContainer>
                    <S.ModalButton onClick={handleChangePhone}>확인</S.ModalButton>
                    <S.ModalButton onClick={() => setModalStyle('')} style={{ "backgroundColor": `${Colors.deepDark}` }}>취소</S.ModalButton>
                  </>}
            </Box>
          </Modal>
          <Modal
            open={openApply === null ? false : true}
            onClose={() => setOpenApply(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={S.modalStyle}>
              <h3>플레이스 등록</h3>
              {index === 0 ?
                <S.Form>
                  <S.InputContainer>
                    <S.InputWrapper>
                      <S.Input type="text" placeholder="네이버 플레이스 URL" value={url} onChange={(e) => setUrl(e.target.value)} />
                    </S.InputWrapper>
                    <S.InputDescription>{`네이버 지도 -> 플레이스 검색 -> "공유" 버튼 클릭`}</S.InputDescription>
                  </S.InputContainer>
                  <S.SubmitButton onClick={(e) => handleValidateUrl(e)}>등록하기</S.SubmitButton>
                </S.Form>
                : index === 1 ?
                  <S.Form>
                    <div>URL을 검사 중입니다.</div>
                    <img src={Loading} alt="loading" />
                  </S.Form>
                  : index === 2 ?
                    <S.Form>
                      <div>URL이 올바르게 검사되었습니다.</div>
                      <div>{`가게 이름: ${storeName}`}</div>
                      <S.ButtonContainer>
                        <S.Button onClick={() => setIndex(0)} $backgroundColor={Colors.deepDark} >다시 입력</S.Button>
                        <S.Button $backgroundColor={Colors.main} onClick={() => setIndex(3)}>다음</S.Button>
                      </S.ButtonContainer>
                    </S.Form>
                    : index === 3 ?
                      <S.Form>
                        <div>문자 받으실 휴대폰 번호를 입력해주세요.</div>
                        <S.InputContainer>
                          <S.InputWrapper>
                            <S.Input type="text" placeholder="휴대폰 번호 입력" value={phone} onChange={(e) => setPhone(e.target.value)} />
                          </S.InputWrapper>
                          <S.InputDescription>휴대폰 번호는 '-'를 제외하고 입력해주세요.</S.InputDescription>
                        </S.InputContainer>
                        <S.SubmitButton onClick={(e) => handleSendSms(e)}>문자 전송</S.SubmitButton>
                      </S.Form>
                      :
                      <S.Form>
                        <div>문자가 전송되었습니다. 잠시만 기다려주세요.</div>
                        <div>문자를 받으셨다면, 확인 버튼을 눌러주세요.</div>
                        <S.ButtonContainer>
                          <S.Button onClick={() => setIndex(3)} $backgroundColor={Colors.deepDark}>다시 입력</S.Button>
                          <S.Button onClick={(e) => handleApply(e)} $backgroundColor={Colors.main}>확인</S.Button>
                          {/* <S.Button onClick={() => navigate('/success')} $backgroundColor={Colors.main}>확인</S.Button> */}
                        </S.ButtonContainer>
                      </S.Form>
              }
            </Box>
          </Modal>
        </S.ListContent>
      </div>
      {chartData && (
        <S.BarChartContainer>
          <Bar options={options(width <= 480)} data={chartData} />
        </S.BarChartContainer>
      )}
    </S.ListContainer>
  );
}

const convertGeminiResult = (geminiResult: number) => {
  switch (geminiResult) {
    case 0:
      return "긍정적";
    case 1:
      return "약간 긍정적";
    case 2:
      return "중립적";
    case 3:
      return "약간 부정적";
    case 4:
      return "부정적";
    case 5:
      return "판단 불가";
    default:
      return "알 수 없는 결과";
  }
}

const timeAgo = (dateString: string): string => {
  const now = new Date();
  const past = new Date(dateString);
  const diff = now.getTime() - past.getTime(); // 차이(milliseconds)

  const minutes = Math.floor(diff / (1000 * 60));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (minutes < 1) {
    return "방금 전";
  } else if (minutes < 60) {
    return `${minutes}분 전`;
  } else if (hours < 24) {
    return `${hours}시간 전`;
  } else {
    return `${days}일 전`;
  }
}

export const Messages = () => {
  const auth = useAuth();
  const [reviewList, setReviewList] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setSelectedIndex(selectedIndex === index ? null : index);
  }

  useEffect(() => {
    console.log(auth.state.isAuthenticated, auth.state.userPlaces, auth.state.userPlaces?.length, auth.state.userPlaces?.[0]?.reviews)
    if (auth.state.isAuthenticated && auth.state.userPlaces && auth.state.userPlaces.length > 0 && auth.state.userPlaces[0].reviews !== undefined) {
      let reviews: any[] = [];
      auth.state.userPlaces.forEach((place) => {
        place.reviews.forEach((review) => {
          reviews.push(review);
        });
      });
      reviews.sort((a, b) => {
        return new Date(b.detected_at).getTime() - new Date(a.detected_at).getTime();
      })
      setReviewList(reviews);
    }
  }, [auth.state.isAuthenticated]);

  return (
    <S.ListContainer>
      <S.ListHeader>
        <S.HeaderTitle style={{ "justifyContent": "flex-start" }}>알림 메세지</S.HeaderTitle>
      </S.ListHeader>
      <S.ItemHeader>
        <S.HeaderTitle style={{ "justifyContent": "flex-start" }}>매장 이름</S.HeaderTitle>
        <S.HeaderTitle>감정 지수</S.HeaderTitle>
        <S.HeaderTitle>작성일</S.HeaderTitle>
      </S.ItemHeader>
      <S.ListContent>
        {
          reviewList.map((review, index) => {
            const isSelected = selectedIndex === index;
            return (
              <S.Item key={index} $isSelected={isSelected} onClick={() => handleClick(index)}>
                <S.ItemContent>
                  <S.ItemName>{review.place_name}</S.ItemName>
                  <div style={{ "display": "flex", "justifyContent": "flex-end", "width": "30%" }}>
                    <S.ItemGemini $gemini={review.gemini_result}>{convertGeminiResult(review.gemini_result)}</S.ItemGemini>
                  </div>
                  <S.ItemCounts>{timeAgo(review.detected_at)}</S.ItemCounts>
                </S.ItemContent>
                {isSelected && (
                  <S.ReviewContent>
                    <div style={{ "fontSize": 14, "fontWeight": "bold" }}>리뷰 내용</div>
                    <div>
                      {review.review_content}
                    </div>
                  </S.ReviewContent>
                )}
              </S.Item>
            );
          })
        }
      </S.ListContent>
    </S.ListContainer>
  );
}

export const Business = () => {
  const auth = useAuth();

  const handleCopyLink = () => {
    const linkToCopy = `${APP_DOMAIN}/apply?sales=${auth.state.userId}`
    navigator.clipboard.writeText(linkToCopy).then(() => {
      alert('링크가 클립보드에 복사되었습니다!');
    }).catch(err => {
      console.error('클립보드 복사 실패:', err);
    });
  };

  return (
    <S.ListContainer>
      <S.ListHeader>
        <S.HeaderTitle style={{ "justifyContent": "flex-start" }}>영업</S.HeaderTitle>
      </S.ListHeader>
      <S.ListContent>
        <S.Item>
          <S.ItemContent>
            <S.ItemName>영업 링크 복사</S.ItemName>
            <div style={{ "display": "flex", "alignItems": "center", "justifyContent": "space-around" }}>
              <ContentCopyIcon style={{ "fontSize": "20px", "color": `${Colors.charcole}`, "cursor": "pointer" }} onClick={handleCopyLink} />
            </div>
          </S.ItemContent>
        </S.Item>
        <Box sx={{
          width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row"
        }}>
          <Box sx={{ width: "50%", height: "100%", display: "flex", flexDirection: "column", gap: "10px", justifyContent: "center", alignItems: "center" }}>
            <StorefrontIcon style={{ "fontSize": "50px", "color": `${Colors.deepDark}` }} />
            <Typography variant="h5">등록된 매장 수</Typography>
            <Typography variant="h6">{auth.state.userPlaces?.length}개</Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ width: "50%", height: "100%", display: "flex", flexDirection: "column", gap: "10px", justifyContent: "center", alignItems: "center" }}>
            <MessageIcon style={{ "fontSize": "50px", "color": `${Colors.deepDark}` }} />
            <Typography variant="h5">총 리뷰 수</Typography>
            <Typography variant="h6">{auth.state.userPlaces?.reduce((acc, place) => acc + place.total_reviews, 0)}개</Typography>
          </Box>
        </Box>
      </S.ListContent>
    </S.ListContainer>
  );
}

export const Admin = () => {
  const users = useUsers();
  const [userIndex, setUserIndex] = useState<number | null>(null);

  const handleChangeAdmin = async () => {
    if (userIndex === null || !users.state.users) return;
    const user = users.state.users[userIndex];
    try {
      console.log("user_id, admin", user.id, user.admin);
      const response = await axios.post(`${API_URL}/users/change_admin?user_id=${user.id}&admin=${user.admin === 1 ? 0 : 1}`);
      console.log("사용자 등급 변경 성공:", response.data);
      window.location.reload();
      alert("사용자 등급이 변경되었습니다.");
    } catch (error) {
      console.error("사용자 등급 변경 실패:", error);
    }
  }

  return (
    <S.ListContainer>
      <S.ListHeader>
        <S.HeaderTitle style={{ "justifyContent": "flex-start" }}>회원관리</S.HeaderTitle>
      </S.ListHeader>
      <S.ListContent>
        {users.state.users && users.state.users.map((user, index) => {
          if (user.admin === 2) return null;
          return (
            <S.Item key={index} onClick={() => setUserIndex(index)}>
              <S.ItemContent>
                <S.ItemName>{user.name}</S.ItemName>
                <S.ItemCounts>{user.phone_number}</S.ItemCounts>
              </S.ItemContent>
            </S.Item>
          );
        })}
      </S.ListContent>
      <Dialog open={userIndex !== null} onClose={() => setUserIndex(null)}>
        <Box sx={{ width: "300px", height: "400px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <Typography variant="h5" style={{ "marginBottom": "20px" }}>
            회원 정보 수정
          </Typography>
          <Typography variant="body1">
            등급: {users.state.users && users.state.users[userIndex ?? 0].admin === 1 ? "영업자" : users.state.users && users.state.users[userIndex ?? 0].admin === 0 ? "일반 사용자" : ""}
          </Typography>
          <Button variant="contained" style={{ "marginTop": "20px" }} onClick={handleChangeAdmin}>등급 변경</Button>
        </Box>
      </Dialog>
    </S.ListContainer>
  );
}
